<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input @change="onQuerySubmit" v-model="query.enterprise_name" placeholder="输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="query.name" placeholder="输入姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
                <el-button type="primary" @click="customCreate" v-if="permission.apiCustomUserCreate">添加人员</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData.data" stripe style="width: 100%">
              <el-table-column label="头像" width="150">
                <template slot-scope="scope">
                  <el-avatar :src="scope.row.avatar_url.substring(0,4) === 'http' ? scope.row.avatar_url : (config.storage + scope.row.avatar_url)"></el-avatar>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" min-width="100">
              </el-table-column>
              <el-table-column prop="created_at" label="创建时间" min-width="150">
              </el-table-column>
              <el-table-column prop="mobile" label="电话号码" min-width="150">
              </el-table-column>
              <el-table-column prop="idcard" label="身份证号码" min-width="150">
              </el-table-column>
              <el-table-column prop="pub_openid" label="公众号openid" min-width="150">
              </el-table-column>
              <el-table-column prop="enterprise_name" label="所属企业" min-width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="230">
                <template slot-scope="scope">
                  <el-button @click="getOutlet(scope.row), drawerEquipment = true" type="text" size="small" v-if="permission.apiCustomOutletChange">配置排口</el-button>
                  <el-button @click="setId(scope.row), drawer = true" type="text" size="small" v-if="permission.apiCustomUserExpireUpdate">更新有效期</el-button>
                  <el-button @click="customDatail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button type="text" size="small" @click="deleteItem(scope.row)" v-if="permission.apiCustomUserDelete">注销</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="changeCurrent" :current-page="query.page" :total="total"></el-pagination>
      </div>
    </el-card>
    <!-- 修改信息 -->
    <el-drawer title="更新信息" :visible.sync="drawer" direction="rtl" :wrapperClosable="false" size="500px">
      <el-card class="box-card">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <el-form label-width="120px">
            <ValidationProvider rules="required" name="到期时间" v-slot="{ errors }">
              <el-form-item required :error="errors[0]" label="到期时间">
                <el-date-picker v-model="query.expired_at" value-format="yyyy-MM-dd" type="date" placeholder="选择日期时间"></el-date-picker>
              </el-form-item>
            </ValidationProvider>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit(upData)">保存更新</el-button>
            </el-form-item>
          </el-form>
        </ValidationObserver>
      </el-card>
    </el-drawer>
    <!-- 配置排口 -->
    <el-drawer title="选择排口权限" :visible.sync="drawerEquipment" direction="rtl" :wrapperClosable="false" size="500px">
      <el-card class="box-card">
        <ValidationObserver ref="observer" v-if="outlets.outlet && outlets.outlet.length" v-slot="{ handleSubmit }">
          <el-form label-width="120px">
            <ValidationProvider rules="required" name="选择排口" v-slot="{ errors }">
              <el-form-item required :error="errors[0]" label="选择排口：">
                <el-checkbox-group v-model="outlets.select">
                  <el-row>
                    <el-col :span="24" v-for="(item, index) in outlets.outlet" :key="index">
                      <el-checkbox  :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </el-col>
                  </el-row>
                </el-checkbox-group>
              </el-form-item>
            </ValidationProvider>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit(setEquipment)">保存配置</el-button>
            </el-form-item>
          </el-form>
        </ValidationObserver>
        <el-empty v-else description="暂无排口" :image-size="300" style="text-align:center;"></el-empty>
      </el-card>
    </el-drawer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiCustomUserList, apiCustomUserDelete, apiCustomUserExpireUpdate, apiCustomOutlet, apiCustomOutletChange } from "src/api/index";
import md5 from 'js-md5';
export default {
  components: {},
  data() {
    return {
      loading: false,
      drawer: false,
      drawerEquipment: false,
      query: {
        id: 0,
        name: '',
        enterprise_name: '',
        expired_at: '',
        page: 1,
        logotype: 'custom'
      },
      tableData: [],
      total: 10,
      outlets: {},
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'custom') {
      _this.query =  query;
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    config: (state) => state.config,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getUserList'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询用户数据
    onQuerySubmit() {
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name + '&enterprise_name=' + this.query.enterprise_name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getUserList();
      } else {       
        this.$router.replace(route);
      }
    },
    //获取用户列表数据
    getUserList() {
      const _this = this;
      _this.loading = true;
      _this.tableData = {};
      apiCustomUserList(_this.query)
      .then((lists) => {
        _this.loading = false;
        _this.tableData = lists;
        _this.total = lists.total;
      })
      .catch((err) => {
        _this.loading = false;
        _this.error(err);
      });
    },
    //跳转用户添加页
    customCreate() {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
       this.$router.push('/admin/personnel/custom-add')
    },
    //跳转用户详情页
    customDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/personnel/custom-detail/' + item.id)
    },
    //删除用户信息
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        apiCustomUserDelete({id:item.id})
        .then((res) => {
          _this.$message({
            message: "注销成功",
            type: "success",
          });
          _this.onQuerySubmit();
        })
        .catch((err) => {
          _this.error(err); 
        }); 
      })
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name + '&enterprise_name=' + this.query.enterprise_name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getUserList();
      } else {       
        this.$router.replace(route);
      }
    },
    //获取用户id和到期时间
    setId(item) {
      this.query.id = item.id;
      this.query.expired_at = item.expired_at;
    },
    //更新客户账号有效期
    upData() {
      const _this = this;
      apiCustomUserExpireUpdate(_this.query)
      .then((res) => {
        _this.drawer = false;
        _this.$message({
          message: "更新成功",
          type: "success",
        });
        _this.onQuerySubmit();
      })
      .catch((err) => {
        _this.drawer = false;
        _this.error(err); 
      })
    },
    //获取企业排口
    getOutlet(item) {
      const _this = this;
      _this.query.id = item.id;
      apiCustomOutlet({id: item.id})
      .then(res => {
        _this.outlets = res;
      })
      .catch(err => {
        _this.error(err);
      })
    },
    //配置客户排口
    setEquipment() {
      const _this = this;
      let form = {
        outlet_ids: _this.outlets.select.join(","),
        id: _this.query.id,
      };
      apiCustomOutletChange(form)
      .then(res => {
        _this.drawerEquipment = false;
        _this.$message({
            message: "保存成功",
            type: "success",
          });
      })
      .catch(err => {
        _this.error(err);
      })
    },
    error(err) {
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
/deep/ .el-table--small td, .el-table--small th {
  padding: 0;
}
/deep/ .el-table td, .el-table th {
  padding: 0;
}
/deep/ .is-leaf {
  padding: 8px 0;
}
/deep/ .el-empty__image {
  margin: 0 auto;
}
</style>